import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

import redirectIfWebview from './middleware/redirectIfWebview'
import { CMS } from './routes-names'

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const { FF_ENABLE_CMS_ABOUT_US, FF_ENABLE_CMS_CONTENT_HUB } =
    useRuntimeConfig().public

  const enableCMSAboutUs = FF_ENABLE_CMS_ABOUT_US?.includes(market.countryCode)
  const enableCMSContentHub = FF_ENABLE_CMS_CONTENT_HUB?.includes(
    market.countryCode,
  )

  const aboutUsRoute: RouteRecordRaw = {
    path: 'about-us',
    name: CMS.ABOUT_US,
    component: () => import('./Landing.vue'),
    meta: {
      cache: 'public, max-age=300',
      owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
    },
  }

  return [
    ...(enableCMSAboutUs ? [aboutUsRoute] : []),
    {
      path: 'webview',
      meta: { layout: 'webview-default' },
      children: [
        {
          name: CMS.WEBVIEW.ARTICLE,
          path: 'c/:category/:pageName',
          component: () => import('~/scopes/article/Article/Article.vue'),
          meta: {
            owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
            isWebview: true,
          },
        },
      ],
    },
    {
      name: CMS.ARTICLE_ROOT_HUB,
      path: 'c',
      component: () =>
        enableCMSContentHub
          ? import('./Landing.vue')
          : import('../article/Hub/pages/ArticlesHub.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },
    {
      name: CMS.ARTICLE_HUB,
      // note the backend always return 'hub_buying_guide'. We need to use that for category hub and fix the breadcrumb logic for the root
      path: 'c/hub/:unifiedName',
      component: () => import('~/scopes/article/Hub/pages/ArticlesHub.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },
    {
      name: CMS.ARTICLE,
      path: 'c/:category/:pageName',
      component: () => import('~/scopes/article/Article/Article.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
        middleware: [redirectIfWebview],
      },
    },

    {
      name: CMS.BACK_STORIES,
      path: 'back-stories/:pageName',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },
    {
      name: CMS.BACK_STORIES_CATEGORY,
      path: 'back-stories/:pageName/:pageCategory',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },

    {
      name: CMS.BRAND_STORE,
      path: 'b/:pageName',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },
    {
      name: CMS.BRAND_STORE_CATEGORY,
      path: 'b/:pageName/:pageCategory',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },

    {
      name: CMS.BUYBACK,
      path: 'buyback/:pageName',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },

    {
      name: CMS.BUYBACK_CATEGORY,
      path: 'buyback/:pageName/:pageCategory',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },
    {
      name: CMS.CAREERS_HOME,
      path: 'careers/:pageName',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },

    {
      name: CMS.CAREERS_CATEGORY,
      path: 'careers/:pageName/:pageCategory',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },

    {
      name: CMS.EVENT,
      path: 'e/:pageName',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },

    {
      name: CMS.GIFT_FINDER_MAIN,
      path: 'gift/:pageName',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },
    {
      name: CMS.GIFT_FINDER_EVENT,
      path: 'gift/:pageName/:pageCategory',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },

    {
      name: CMS.REFER_FRIEND,
      path: 'refer-friend',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },

    {
      name: CMS.SELLER,
      path: 'seller/:pageName',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },

    {
      name: CMS.SERVICE,
      path: 'service/:pageName',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },

    {
      name: CMS.STATEMENT,
      path: 'accessibility-statement',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },

    {
      name: CMS.END_FAST_TECH,
      path: 'end-fast-tech',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },

    {
      name: CMS.E2E_PAGE,
      path: 'e2e/:pageName',
      component: () => import('./Landing.vue'),
      meta: {
        owners: ['bot-squad-content-front', 'bot-group-onboard-front'],
      },
    },
  ]
}
